.apartments {
  padding-bottom: 8.5rem;
  background-color: #dfe4e0;

  @media (max-width: em(600)) {
    padding-block: 5rem;
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    gap: 2.8rem;

    @media (max-width: em(1460)) {
      flex-direction: column;
    }

    @media (max-width: em(600)) {
      margin-top: 1.7rem;
    }
  }

  &-info {
    flex-basis: 50%;

    &__price {
      margin-top: 5.2rem;
      font-family: var(--font-georgia);
      font-weight: 400;
      font-size: fluid(2.8rem, 1.8rem);
      line-height: 67.857%;
      letter-spacing: -0.056rem;
      color: var(--clr-default-900);

      @media (max-width: em(600)) {
        margin-top: 2rem;
      }
    }

    &__discount {
      margin-top: 1.5rem;
      font-family: var(--font-forum);
      font-weight: 400;
      font-size: fluid(2rem, 1.6rem);
      line-height: 95%;
      letter-spacing: 0.08rem;
      color: #218638;
    }

    .btn {
      margin-top: 3rem;

      @media (max-width: em(600)) {
        margin-top: 2rem;
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 37.7rem 24.1rem 24.1rem;
    grid-template-rows: 28rem 25.7rem;
    gap: 3rem;

    @media (max-width: em(1460)) {
      margin-inline: auto;
    }

    @media (max-width: em(964)) {
      grid-template-columns: 1.55fr 1fr 1fr;
      grid-template-rows: 0.9fr 0.8fr;
    }

    @media (max-width: em(515)) {
      gap: 1rem;
    }

    &__img {
      overflow: hidden;
      border-radius: var(--radius);

      // @media (max-width: em(964)) {
      //   width: 16rem;
      // }
    }

    &__img_1 {
      grid-row: span 2;

      // @media (max-width: em(964)) {
      //   width: 30rem;
      // }
    }

    &__img_2 {
      grid-column: span 2;

      // @media (max-width: em(964)) {
      //   width: 40rem;
      // }
    }
  }
}
