.up {
  position: fixed;
  right: 5rem;
  bottom: 13rem;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition);

  @media (max-width: em(600)) {
    right: 2rem;
  }

  @media (max-width: em(400)) {
    border-radius: 1rem;
    width: 4rem;
    height: 4rem;
    background-color: #fee3e3;
  }

  * {
    transition: stroke var(--transition);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover * {
    stroke: #65c7e8;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}
