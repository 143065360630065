.header {
  padding-block: 4rem;

  @media (max-width: em(600)) {
    padding-block: 0.5rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  &__phone {
    position: relative;
    padding-inline: 4rem;
    font-family: var(--font-frank);
    font-weight: 700;
    font-size: fluid(2.4rem, 1.8rem);
    color: var(--clr-default-900);

    @media (max-width: em(600)) {
      margin-left: auto;
      padding: 0 1rem 0 0;
      font-size: 1.4rem;
    }

    &_bg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: em(600)) {
        display: none;
      }
    }
  }
}
