// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 159rem;

:root {
  // base
  --font-family: "Forum", sans-serif;
  --font-weight: 400;
  --content-width: 156rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.6s;
  --radius: 0.8rem;

  // colors
  --clr-default-100: #fff;
  --clr-default-200: #fcfffd;
  --clr-default-700: #0c0b10;
  --clr-default-800: #0b0804;
  --clr-default-900: #000;

  //clr bgs
  --clr-bg-100: #e6d2ba;
  --clr-bg-900: linear-gradient(
    270deg,
    rgba(240, 173, 94, 0.4) 0%,
    rgba(125, 164, 193, 0.4) 100%
  );

  //fonts
  --font-forum: "Forum", sans-serif;
  --font-frank: "FrankRuhlHofshi", sans-serif;
  --font-frank-400: "FrankRuhlHofshi-Regular", sans-serif;
  --font-inter: "Inter", sans-serif;
  --font-gabriela: "Gabriela", sans-serif;
  --font-georgia: "georgia", sans-serif;
  --font-manrope: "Manrope", sans-serif;
  --font-podkova: "Podkova", sans-serif;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-size: 1.6rem;
  color: var(--clr-default-400);

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }

  /* scroll bar */
  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffbdbd;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--clr-default-100);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-inline: auto;
  padding-inline: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Components - UI
@import "./ui";

// libs
@import "./libs/datapicker";
@import "./libs/swiper";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";
@import "./components/ui/up";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";

// Components - Common

// Components - Pages
@import "./components/pages/index/hero";
@import "./components/pages/index/about";
@import "./components/pages/index/services";
@import "./components/pages/index/apartments";
@import "./components/pages/index/feedback";
@import "./components/pages/index/instagram";
@import "./components/pages/index/map";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
