.footer {
  padding-block: 2rem 2.5rem;
  background-color: #c6b6a3;

  @media (max-width: em(500)) {
    padding-block: 1.5rem;
  }

  .container {
    position: relative;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    @media (max-width: em(600)) {
      width: 8.5rem;
      height: 4rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-contacts {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    gap: 1.8rem;

    @media (max-width: em(992)) {
      flex-direction: row;

      @media (max-width: em(374)) {
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      font-family: var(--font-frank);
      font-weight: 400;
      font-size: fluid(2.4rem, 1rem);
      letter-spacing: 0.096rem;
      text-transform: uppercase;
      color: var(--clr-default-900);
      gap: 0.8rem;

      @media (max-width: em(992)) {
        margin-top: 0;
      }

      svg {
        @media (max-width: em(500)) {
          width: 2rem;
        }
      }
    }
  }

  &__clouds {
    position: absolute;
    right: 0;
    bottom: -2.5rem;
  }
}
