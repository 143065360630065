@use "sass:math";

$width: 15.625em;
$radius: $width * 0.016891;
$transition: 0.3s;
$font-size: $width * 0.056;
$lightblue: lightblue;

/*
  All a user has to do to change the calendar size is
  change the font-size on the container and everything
  magically resizes accordingly. Relative units ftw!
*/
.qs-datepicker-container {
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 0.2rem solid #5e4e49;
  border-radius: var(--radius);
  width: 31rem;
  font-family: sans-serif;
  font-size: 1rem;
  color: black;
  background: white;
  user-select: none;

  * {
    box-sizing: border-box;
  }
}

.qs-centered {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.qs-hidden {
  display: none;
}

.qs-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 100%;
  height: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.75);
  opacity: 1;
  transition: opacity $transition;

  &.qs-hidden {
    z-index: -1;
    opacity: 0;
  }

  .qs-overlay-year {
    // Overlay year input element.
    display: block;
    margin: 0 auto;
    border: none;
    border-bottom: 1px solid white;
    border-radius: 0;
    padding: 0.25em 0;
    width: 80%;
    font-size: $font-size;
    text-align: center;
    color: white;
    background: transparent;

    // https://goo.gl/oUuGkG
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .qs-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5em;
    cursor: pointer;
  }

  .qs-submit {
    margin: 0 auto auto;
    border: 1px solid white;
    border-radius: $radius;
    padding: 0.5em;
    background: rgba(128, 128, 128, 0.4);
    cursor: pointer;

    &.qs-disabled {
      border-color: gray;
      color: gray;
      cursor: not-allowed;
    }
  }

  .qs-overlay-month-container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  .qs-overlay-month {
    display: flex;
    align-items: center;
    justify-content: center;
    width: #{"calc(100% / 3)"};
    opacity: 0.5;
    transition: opacity math.div($transition, 2);
    cursor: pointer;

    &.active,
    &:hover {
      opacity: 1;
    }
  }
}

.qs-controls {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: lightgray;
  filter: blur(0px);
  transition: filter $transition;

  &.qs-blur {
    filter: blur(5px);
  }
}

.qs-arrow {
  position: relative;
  border-radius: 0.3rem;
  width: 2rem;
  height: 2rem;
  transition: background 0.15s;
  cursor: pointer;

  &:hover {
    &.qs-left:after {
      border-right-color: black;
    }

    &.qs-right:after {
      border-left-color: black;
    }

    background: rgba(0, 0, 0, 0.1);
  }

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  &.qs-left:after {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'><path d='M5.33335 13.3332L10.6667 7.99984L5.33335 2.6665L4.38669 3.61317L8.77335 7.99984L4.38669 12.3865L5.33335 13.3332Z' fill='black' fill-opacity='0.75'/></svg>");
    transform: translate(-50%, -50%) rotate(180deg);
  }

  &.qs-right:after {
    content: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'><path d='M5.33335 13.3332L10.6667 7.99984L5.33335 2.6665L4.38669 3.61317L8.77335 7.99984L4.38669 12.3865L5.33335 13.3332Z' fill='black' fill-opacity='0.75'/></svg>");
  }
}

.qs-month-year {
  border-bottom: 1px solid transparent;
  font-weight: bold;
  transition: border 0.2s;

  &:not(.qs-disabled-year-overlay) {
    cursor: pointer;
  }

  &:focus,
  &:active:focus {
    outline: none;
  }
}

.qs-month {
  padding-right: 0.5ex;
}

.qs-year {
  padding-left: 0.5ex;
}

.qs-squares {
  display: flex;
  flex-wrap: wrap;
  padding: $width * 0.02;
  filter: blur(0px);
  transition: filter $transition;

  &.qs-blur {
    filter: blur(5px);
  }
}

.qs-square {
  display: flex;
  align-items: center;
  justify-content: center;

  // Overriden for date-range dates below.
  width: #{"calc(100% / 7)"};
  height: math.div($width, 10);
  transition: background 0.1s;
  cursor: pointer;

  &:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active) {
    border-radius: 50%;

    &:hover {
      color: var(--clr-default-100);
      background: #5e4e49;
    }
  }
}

// Today's date
.qs-current {
  color: var(--clr-default-300);
}

/*
  3 possibilities:
    1. Single, active date.
    2. Daterange start selection.
    3. Daterange end selection.
*/
.qs-active,
.qs-range-start,
.qs-range-end {
  border-radius: 50%;
  color: var(--clr-default-100);
  background: #5e4e49;
}

// Daterange start selection.
.qs-range-start {
  &:not(.qs-range-6) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// Daterange middle selections.
.qs-range-middle {
  background: lighten($lightblue, 10%);

  &:not(.qs-range-0):not(.qs-range-6) {
    border-radius: 0;
  }

  &.qs-range-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.qs-range-6 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

// Daterange end selection.
.qs-range-end {
  &:not(.qs-range-0) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.qs-disabled,
.qs-outside-current-month {
  opacity: 0.2;
}

.qs-disabled {
  cursor: not-allowed;
}

.qs-empty {
  cursor: default;
}

.qs-day {
  font-weight: bold;
  color: gray;
  cursor: default;
}

.qs-event {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    width: $width * 0.03;
    height: $width * 0.03;
    background: #07f;
  }
}

//custom
.qs-datepicker-container {
  border-style: solid;
  border-width: 0.2rem;
  border-color: #5e4e49;
  border-top: 0.1rem solid #dfc6a7;
  border-radius: 0.8rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  padding: 1.4rem 0.5rem;
  width: 34.5rem;
  font-family: var(--font-inter);
  transform: translateY(-0.4rem);

  @media (max-width: em(641)) {
    display: inline-block;
    width: 100%;

    &.qs-hidden {
      display: none;
    }
  }
}

.qs-controls {
  background-color: #fff;
}

.qs-month-year {
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 137.5%;
  letter-spacing: -0.032rem;
  text-align: center;
  color: #5e4e49;
}

.hero-form .qs-month,
.hero-form .qs-year {
  font-family: var(--font-inter);
}

.qs-squares {
  margin-top: 1.1rem;
  font-size: 1.4rem;
}

.qs-square {
  width: 4rem;
  height: 4rem;
}

.qs-square.qs-day {
  margin-bottom: 0.3rem;
  border-radius: none;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 135.714%;
  letter-spacing: -0.028rem;
  text-align: center;
  color: #a5938d;
}

.qs-day:nth-child(7) {
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.qs-day:nth-child(1) {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}

.qs-num {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 142.857%;
  text-align: center;
}
