.feedback {
  position: relative;
  background-color: #dfe4e0;

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  &__flex {
    margin-top: 5rem;
  }

  &__swiper {
    display: flex;
    margin-left: 0;
    width: 80%;

    @media (max-width: em(741)) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 65rem;

    @media (max-width: em(741)) {
      max-width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      display: block;
      border-style: solid;
      border-width: 0.1rem;
      border-color: var(--clr-default-100);
      border-radius: 1rem;
      width: 90%;
      height: 103%;
      transform: translate(-50%, -50%);
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: -0.9rem;
      z-index: 2;
      display: block;
      width: 40%;
      height: 0.3rem;
      background-color: #dee5e3;
      transform: translate(-50%);
    }
  }

  &__item {
    position: relative;
    z-index: 2;
    border-style: solid;
    border-width: 0.1rem;
    border-color: var(--clr-default-100);
    border-radius: 1rem;
    padding: 5.5rem 7rem 16rem 10rem;
    height: 100%;
    background-color: #dfdddb;

    @media (max-width: em(1499)) {
      padding: 3rem 4rem 10rem 4rem;
    }
  }

  &__decoration {
    position: absolute;
    left: 50%;
    top: -3rem;
    z-index: 1;
    transform: translateX(-50%);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__img {
    border-radius: 50%;
    width: max-content;
    box-shadow: 3rem 3rem 8rem -3rem rgb(0, 0, 0);
  }

  &__text {
    margin-top: 4.8rem;
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: fluid(2.1rem, 1.6rem);
    line-height: 150%;
    letter-spacing: 0.084rem;
    color: var(--clr-default-700);
  }

  &__author {
    position: absolute;
    left: 10rem;
    bottom: 6rem;
    font-family: var(--font-manrope);
    font-weight: 500;
    font-size: 1.9545rem;
    line-height: 94.5%;
    color: var(--clr-default-700);

    @media (max-width: em(1499)) {
      left: 4rem;
      bottom: 4rem;
    }
  }

  &__more {
    position: relative;
    display: none;
    margin-top: 2rem;
    margin-left: auto;
    width: max-content;
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 0.048rem;

    @media (max-width: em(741)) {
      display: inline-block;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0.3rem;
      z-index: 1;
      display: inline-block;
      width: 100%;
      height: 0.1rem;
      background-color: var(--clr-default-900);
    }
  }

  &__rhombus {
    position: absolute;

    @media (max-width: em(1100)) {
      display: none;
    }

    &_1 {
      right: 50%;
      top: -4rem;
    }

    &_2 {
      right: 46%;
      top: 2rem;
    }
  }

  &__clouds {
    position: absolute;
    right: 0;
    top: 73%;
    transform: translateY(-50%);
  }
}
