.about {
  position: relative;
  padding-bottom: 8.5rem;
  background-color: var(--clr-bg-100);

  @media (max-width: em(600)) {
    padding-bottom: 4rem;
  }

  &__decoration {
    position: absolute;

    &_1 {
      left: 37%;
      top: 2rem;
    }

    &_2 {
      left: 40%;
      top: -1rem;
      transform: translateY(-3rem);
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    @media (max-width: em(1366)) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__info {
    flex-basis: 53%;

    @media (max-width: em(1366)) {
      max-width: 100rem;

      @media (max-width: em(1250)) {
        max-width: 100%;
      }
    }

    .text {
      margin-top: 5rem;

      @media (max-width: em(600)) {
        margin-top: 1.7rem;
      }
    }
  }

  &__img {
    @media (max-width: em(1366)) {
      align-self: end;
      width: 80rem;

      @media (max-width: em(1250)) {
        margin-inline: auto;
        width: 70%;
      }

      @media (max-width: em(750)) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }
}
