.services {
  position: relative;
  padding-block: 15rem 7rem;
  background: var(--clr-bg-900);

  @media (max-width: em(600)) {
    padding-block: 10rem 7rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 8rem;
    background: linear-gradient(180deg, rgba(223, 198, 167, 0) 0%, #dfe4e0);

    @media (max-width: em(600)) {
      height: 5rem;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 8rem;
    background: linear-gradient(
      0deg,
      rgba(223, 198, 167, 0) 0%,
      rgba(230, 210, 186, 0.71) 35.59%,
      rgba(230, 210, 186, 0.89) 63.72%,
      #e6d2ba 100%
    );

    @media (max-width: em(600)) {
      height: 5rem;
    }
  }

  &__decoration {
    position: absolute;
    z-index: 1;

    &_1 {
      left: 50%;
      top: 60%;
      transform: translate(-100rem, -50%);
    }

    &_2 {
      left: 50%;
      top: 90%;
      transform: translate(-40rem, -50%);
    }

    &_3 {
      left: 50%;
      top: 97%;
      transform: translate(-36rem, -50%);
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__clouds {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 6rem;
    gap: 5rem;

    @media (max-width: em(1100)) {
      flex-wrap: wrap;
      justify-content: start;
      gap: 3.6rem;

      @media (max-width: em(900)) {
        justify-content: center;
      }

      @media (max-width: em(600)) {
        gap: 2rem;
      }
    }
  }

  &__item {
    position: relative;
    flex-basis: 20%;
    padding-bottom: 8rem;

    @media (max-width: em(1100)) {
      flex-basis: 30%;

      @media (max-width: em(900)) {
        flex-basis: 45%;
      }
    }
  }

  &__caption {
    font-family: var(--font-podkova);
    font-weight: 400;
    font-size: fluid(2.4rem, 1.8rem);
    text-align: center;
    text-transform: capitalize;
    color: var(--clr-default-900);
  }

  &__ico {
    margin-top: 2rem;
    margin-inline: auto;
    width: max-content;

    @media (max-width: em(500)) {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
}
