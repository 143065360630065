.map {
  padding-block: 6rem 3.3rem;
  background: rgb(219, 221, 219);
  background: linear-gradient(
    180deg,
    rgba(219, 221, 219, 1) 0%,
    rgba(230, 218, 200, 1) 100%
  );

  @media (max-width: em(600)) {
    padding-bottom: 0;
  }

  .container {
    @media (max-width: em(600)) {
      padding: 0;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  .title {
    @media (max-width: em(600)) {
      padding-left: 1.5rem;
    }
  }

  &__caption {
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: fluid(2.4rem, 1.8rem);
    letter-spacing: 0.096rem;
    text-transform: capitalize;
    color: var(--clr-default-900);

    @media (max-width: em(600)) {
      padding-left: 1.5rem;
    }

    svg {
      width: 100%;
      transform: translateY(0.466rem);

      @media (max-width: em(600)) {
        width: 2rem;
      }
    }
  }

  &__iframe {
    position: relative;
    display: flex;
    margin-top: 3rem;
    height: 78rem;

    @media (max-width: em(956)) {
      height: 60rem;

      @media (max-width: em(600)) {
        height: 30rem;
      }

      @media (max-width: em(500)) {
        height: 20rem;
      }
    }
  }

  &__board {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-top-right-radius: 4rem;
    border-bottom-left-radius: 2rem;

    @media (max-width: em(600)) {
      display: none;
    }
  }

  &__info,
  &__img {
    flex-basis: 50%;
    width: 27rem;
    height: 21rem;
  }

  &__img {
    background-image: url("../img/map-img.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(570)) {
      display: none;
    }
  }

  &__info {
    padding: 1.9rem 2rem 4.7rem 2.7rem;
    background-color: var(--clr-default-100);

    @media (max-width: em(570)) {
      flex-basis: 100%;
    }
  }

  &__title {
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 55.556%;
    letter-spacing: 0.144rem;
    text-transform: capitalize;
    color: #07169a;
  }

  &__rating {
    margin-top: 2rem;
    border-radius: 2rem;
    padding: 1.4rem 1.2rem;
    width: max-content;
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: 3.2rem;
    line-height: 62.5%;
    letter-spacing: 0.128rem;
    text-transform: capitalize;
    color: var(--clr-default-100);
    background-color: #14089b;
  }

  &__address {
    margin-top: 1.4rem;
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: 2rem;
    line-height: 100%;
    letter-spacing: 0.08rem;
    text-transform: capitalize;
    color: var(--clr-default-900);

    span {
      display: block;

      &:not(:first-of-type) {
        margin-top: 0.4rem;
      }
    }
  }

  &__ico {
    margin-top: 5rem;
    margin-inline: auto;
    width: max-content;

    @media (max-width: em(600)) {
      display: none;
    }
  }
}
