.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

a {
  color: var(--clr-default-900);
  transition: color var(--transition);

  svg * {
    transition: fill var(--transition);
  }

  &:hover {
    color: var(--clr-default-100);

    svg * {
      fill: var(--clr-default-100);
    }
  }

  &:active {
    color: var(--clr-default-900);

    svg * {
      fill: var(--clr-default-900);
    }
  }
}

.section {
  padding-top: 8.5rem;

  @media (max-width: em(600)) {
    padding-top: 4rem;
  }
}

.title {
  font-family: var(--font-forum);
  font-weight: 400;
  font-size: fluid(3.6rem, 2rem);
  letter-spacing: 0.144rem;
  text-transform: uppercase;
  color: var(--clr-default-900);
}

.text {
  font-family: var(--font-forum);
  font-weight: 400;
  font-size: fluid(2.4rem, 1.6rem);
  line-height: 150%;
  color: var(--clr-default-900);
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--radius);
  padding: 1.6rem 0 1.6rem 3.2rem;
  width: max-content;
  background-color: var(--clr-default-900);

  @media (max-width: em(600)) {
    padding: 0.9rem 1.6rem 1.1rem;
  }

  &::after {
    content: "";
    position: absolute;

    right: -2rem;
    top: 50%;
    display: block;
    border-radius: var(--radius);
    width: 5.3rem;
    height: 5.3rem;
    background-color: var(--clr-default-900);
    transform: translateY(-50%) rotate(45deg);

    @media (max-width: em(600)) {
      width: 4.7rem;
      height: 4.7rem;
    }
  }

  span {
    position: relative;
    z-index: 1;
    font-family: var(--font-gabriela);
    font-weight: 400;
    font-size: fluid(2.4rem, 1.6rem);
    letter-spacing: 0.096rem;
    text-transform: uppercase;
    color: #fffbf5;
  }

  svg {
    position: relative;
    z-index: 1;
    margin-left: 2rem;
    width: 4rem;
    height: 4rem;
  }
}

picture {
  display: flex;
}

.input {
  outline: none;
  border-style: solid;
  border-width: 0.2rem;
  border-color: #5e4e49;
  border-radius: 0.6rem;
  padding: 1.3rem 1.2rem;
  font-family: var(--font-inter);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 137.5%;
  letter-spacing: -0.032rem;

  &::placeholder {
    color: #c0b9b7;
  }
}

.bg {
  background-image: url("../img/hero-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
