.instagram {
  position: relative;
  position: relative;
  padding-block: 16.6rem 21.8rem;
  background: var(--clr-bg-900);

  @media (max-width: em(600)) {
    padding-block: 8rem 3rem;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 8rem;
    background: linear-gradient(0deg, rgba(223, 198, 167, 0) 0%, #dfe4e0);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 8rem;
    background: linear-gradient(180deg, rgba(223, 198, 167, 0) 0%, #dbdcda);
  }

  &__caption {
    margin-top: 6rem;
    max-width: 44.5rem;
    font-family: var(--font-forum);
    font-weight: 400;
    font-size: 2.4rem;
    font-size: fluid(2.4rem, 1.8rem);
    line-height: 150%;
    letter-spacing: 0.096rem;
    color: var(--clr-default-900);

    @media (max-width: em(600)) {
      margin-top: 2rem;
    }

    a {
      color: #004373;

      &:hover {
        color: var(--clr-default-100);
      }
    }
  }

  .btn {
    order: 2;
    margin-top: 5rem;
    gap: 16rem;

    @media (max-width: em(941)) {
      gap: 5rem;
    }

    @media (max-width: em(600)) {
      margin-top: 2rem;
      gap: 1rem;
    }

    .instagram-btn-span {
      @media (max-width: em(600)) {
        display: none;
      }
    }
  }

  &__ico,
  &__img {
    position: absolute;
  }

  &__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem;
    width: 6.6rem;
    height: 6.8rem;
    box-shadow: 0 0.7rem 2rem 0 rgba(0, 0, 0, 0.3);
    background-color: var(--clr-default-100);
    background-blend-mode: overlay, normal;
    opacity: 0.6;
    backdrop-filter: blur(0.75rem);

    @media (max-width: em(820)) {
      width: 4rem;
      height: 4rem;

      svg {
        @media (max-width: em(640)) {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &_1 {
      right: 76rem;
      top: -9rem;

      @media (max-width: em(1175)) {
        left: 35rem;
        right: auto;
      }

      @media (max-width: em(820)) {
        left: auto;
        right: 20rem;
        top: 0;
      }

      @media (max-width: em(640)) {
        display: none;
      }
    }

    &_2 {
      right: 55rem;
      top: 145%;

      @media (max-width: em(1175)) {
        top: 69%;

        @media (max-width: em(992)) {
          right: 40rem;
        }

        @media (max-width: em(820)) {
          right: 2rem;
        }

        @media (max-width: em(640)) {
          left: 28rem;
          right: auto;
          top: -3rem;
        }
      }
    }

    &_3 {
      right: 25rem;
      top: 33%;

      @media (max-width: em(1175)) {
        right: 48rem;

        @media (max-width: em(992)) {
          right: 30rem;
        }

        @media (max-width: em(820)) {
          right: 25rem;
        }

        @media (max-width: em(640)) {
          left: 40rem;
          right: auto;
        }

        @media (max-width: em(480)) {
          left: 30rem;
        }
      }
    }
  }

  &__img {
    &_1 {
      right: 40rem;
      top: 16%;
      width: 28rem;

      @media (max-width: em(1175)) {
        position: relative;
        right: auto;
        top: auto;
        margin-top: 2rem;
      }

      @media (max-width: em(820)) {
        width: 23rem;

        @media (max-width: em(480)) {
          width: 17rem;
        }
      }
    }

    &_2 {
      right: 15rem;
      top: 61%;
      z-index: 1;
      width: 25rem;

      @media (max-width: em(1175)) {
        right: 25rem;

        @media (max-width: em(992)) {
          right: 15rem;
        }

        @media (max-width: em(820)) {
          width: 18rem;
        }

        @media (max-width: em(640)) {
          left: 29rem;
          right: auto;
        }

        @media (max-width: em(480)) {
          left: 24rem;
          width: 15rem;
        }
      }
    }

    &_3 {
      right: 0;
      top: 22%;
      width: 25rem;

      @media (max-width: em(1175)) {
        right: 15rem;

        @media (max-width: em(992)) {
          right: 5rem;
        }

        @media (max-width: em(820)) {
          width: 18rem;
        }

        @media (max-width: em(640)) {
          left: 23rem;
          right: auto;
          top: 34%;
          z-index: 2;
        }

        @media (max-width: em(480)) {
          left: 15.5rem;
          width: 15rem;
        }
      }
    }
  }

  &__decoration {
    position: absolute;

    &_1 {
      left: 50%;
      top: 105%;
      transform: translate(-40rem, -50%);
    }

    &_2 {
      left: 51%;
      top: 93%;
      transform: translate(-36rem, -50%);
    }
  }

  &__clouds {
    left: 0;
    top: 66%;
  }
}
